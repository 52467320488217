import type { FC } from 'react'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { useData } from '@/logic/data'
import { getLegalDocumentUrl } from '@/logic/legalDocuments/helpers'
import type { TLegalDocuments } from '@/types/legalDocuments'
import { ELegalDocumentType } from '@/types/legalDocuments'
import { formatLocalizedString } from '@/utils/formatUtils'
import { messages } from './messages'
import type { TConsultingFormRegistrationRulesComponentProps } from './types'

const ConsultingFormRegistrationRulesComponent: FC<TConsultingFormRegistrationRulesComponentProps> = ({
  className = 'text_small text_super-small-md',
}) => {
  const { data: legalDocuments = [] } = useData<TLegalDocuments>('legalDocuments')
  const linkUrl = getLegalDocumentUrl(legalDocuments, ELegalDocumentType.registrationPolicy)

  return (
    <div className={className}>
      {formatLocalizedString(messages.text, {
        link: (
          <CommonLink className="underline underline_green color_green" url={linkUrl} shouldOpenInNewWindow>
            <FormattedMessage id={messages.link} />
          </CommonLink>
        ),
      })}
    </div>
  )
}

export const ConsultingFormRegistrationRules = memo(ConsultingFormRegistrationRulesComponent)

import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/auctions";
import type { GetAuctionV2QueryResponse, GetAuctionV2QueryParams, GetAuctionV2400, GetAuctionV2404 } from "../types/GetAuctionV2";

export const getAuctionV2QueryKey = (params: GetAuctionV2QueryParams) =>     [{url:`/v2/auction`},...(params ? [params] : [])] as const;
      export function getAuctionV2QueryOptions <TData = GetAuctionV2QueryResponse, TError = GetAuctionV2400 | GetAuctionV2404>(params: GetAuctionV2QueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getAuctionV2QueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/v2/auction`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @description Метод предназначен для получения данных аукциона по ID аукциона. https://confluence.poleinvest.ru/pages/viewpage.action?pageId=425482468

 * @link /v2/auction
 */

export function useGetAuctionV2 <TData = GetAuctionV2QueryResponse, TError = GetAuctionV2400 | GetAuctionV2404>(params: GetAuctionV2QueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getAuctionV2QueryKey(params);

  const query = useQuery<TData, TError>({
    ...getAuctionV2QueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};

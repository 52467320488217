import Cookies from 'js-cookie'
import some from 'lodash/some'
import type { CreateMarketingData } from '@/api/kubik/monolith'
import { createMarketingEntry } from '@/api/kubik/monolith'
import { SERVER_DATE_FORMAT } from '@/constants/common'
import { formatDate } from '@/utils/formatUtils'

export async function sendMarketingRequest() {
  const queries = new URLSearchParams(window.location.search)

  const utmCampaign = queries.get('utm_campaign')
  const utmMedium = queries.get('utm_medium')
  const utmSource = queries.get('utm_source')
  const utmContent = queries.get('utm_content')
  const utmTerm = queries.get('utm_term')

  const hasSomeUtm = Boolean(utmCampaign || utmMedium || utmSource || utmContent || utmTerm)

  if (hasSomeUtm) {
    const isEveryUtmSame =
      utmCampaign === Cookies.get('utm_campaign') &&
      utmMedium === Cookies.get('utm_medium') &&
      utmSource === Cookies.get('utm_source') &&
      utmContent === Cookies.get('utm_content') &&
      utmTerm === Cookies.get('utm_term')

    if (isEveryUtmSame) return
  }

  const checkOnString = (param?: Maybe<string>) => (typeof param === 'string' ? param : undefined)

  const utmParams: Omit<CreateMarketingData, 'entryDate'> = {
    utmCampaign: checkOnString(utmCampaign),
    utmSource: checkOnString(utmSource),
    utmMedium: checkOnString(utmMedium),
    utmContent: checkOnString(utmContent),
    utmTerm: checkOnString(utmTerm),
  }

  const hasUtmParams = some(utmParams, Boolean)

  if (!hasUtmParams) return

  const createRequestBody: CreateMarketingData = {
    entryDate: formatDate(new Date(), SERVER_DATE_FORMAT),
    ...utmParams,
  }

  const clientId = Cookies.get('agromarket_client_id')

  if (!clientId) return

  try {
    await createMarketingEntry(createRequestBody, { 'X-Client-Id': clientId })
  } catch (error) {
    return
  }

  Cookies.set('utm_campaign', utmCampaign || 'EMPTY')
  Cookies.set('utm_medium', utmMedium || 'EMPTY')
  Cookies.set('utm_source', utmSource || 'EMPTY')
  Cookies.set('utm_content', utmContent || 'EMPTY')
  Cookies.set('utm_term', utmTerm || 'EMPTY')
}

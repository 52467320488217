import type { TMessages } from '@/types/TMessages'

export const messages = {
  header: {
    badge: 'Доступно во всех регионах РФ',
    title: 'Деньги для агробизнеса\n<Highlighted>за 7 дней</Highlighted>',
  },
  condition: {
    '1': 'Одобряем 83% заявок от фермеров',
    '2': 'Бесплатно сопровождаем и помогаем на всех этапах',
    '3': 'Возможность погашения в конце срока',
    '4': 'Отсутствие скрытых комиссий и платежей',
  },
  form: {
    header: 'Предварительная консультация',
    description: 'Отправьте заявку на консультацию, и с вами свяжется менеджер в течение часа в рабочий день',
    loanAmount: 'Сумма',
    purpose: {
      goal: 'Цель',
      agriculturalProducts: 'Покупка товаров / ТМЦ',
      agrotechnics: 'Покупка техники',
      workingСapital: 'Оборотные средства',
      capitalExpenses: 'Капитальные затраты',
      businessDevelopment: 'Развитие бизнеса',
      refinancing: 'Рефинансирование',
    },
    phone: 'Номер телефона',
    submit: 'Получить консультацию',
    footer: {
      text: 'Ввод номера телефона подтверждает ваше согласие с ',
      link: 'политикой обработки персональных данных',
    },
    error: {
      required: 'Необходимо заполнить',
      incorrectPrice: 'Некорректная сумма',
    },
  },
  success: {
    subtitle: 'Ожидайте обратную связь по заявке в течение дня',
  },
} satisfies TMessages

import React from 'react'
import classNames from 'classnames'
import { Button, GlassMorphIcon } from '@/components/ui'
import { messages } from './messages'
import type { ILoanProductOrderErrorProps } from './types'
import './styles.scss'

export const FinancingProductError: React.FC<ILoanProductOrderErrorProps> = props => {
  const { className, onResetStep } = props

  return (
    <div className={classNames(className, 'financingProductError')}>
      <GlassMorphIcon image="red/info" className="financingProductError__icon" />

      <h4 className="financingProductError__title">{messages.title}</h4>
      <div className="financingProductError__subtitle">{messages.subtitle}</div>
      <div className="financingProductError__actions">
        <Button modifiers={['green']} onClick={onResetStep}>
          {messages.actions.done}
        </Button>
      </div>
    </div>
  )
}

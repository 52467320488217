import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { INITIAL_FORM_VALUES, RANGES } from 'src/components/composed/LoanProductCalc/constants'
import { messages } from 'src/components/composed/LoanProductCalc/messages'
import { pushGtmEventOnChangeCalculate } from '@/components/composed/LoanProductCalc/gtm-helpers'
import { SliderValueMoney, SliderValueTerm } from '@/components/ui/ReactHookFormFields'
import { getUserGtmInfo } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import type { RootState } from '@/redux/interfaces'

const mapState = (state: RootState) => ({
  userGtmInfo: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

export const LoanProductCalcForm: FC = () => {
  const { control } = useFormContext()
  const { userGtmInfo, userRegion } = useSelector(mapState)

  const settingsSliderSum = useMemo(
    () => ({ min: RANGES.sum.minValue, max: RANGES.sum.maxValue, step: RANGES.sum.step, defaultValue: INITIAL_FORM_VALUES.sum }),
    [],
  )

  const settingsSliderDays = useMemo(
    () => ({ min: RANGES.days.minValue, max: RANGES.days.maxValue, step: RANGES.days.step, defaultValue: INITIAL_FORM_VALUES.days }),
    [],
  )

  return (
    <div className="loanProductCalcForm">
      <div className="loanProductCalcForm__fields">
        <SliderValueMoney
          name="sum"
          settings={settingsSliderSum}
          control={control}
          label={messages.form.size}
          pointsCount={2}
          onChange={value => pushGtmEventOnChangeCalculate(userGtmInfo, userRegion.name, `${messages.gtmMessages.sum}-${value}`)}
        />

        <SliderValueTerm
          name="days"
          pointsType="days"
          settings={settingsSliderDays}
          control={control}
          label={messages.form.term}
          pointsCount={2}
          onChange={value => pushGtmEventOnChangeCalculate(userGtmInfo, userRegion.name, `${messages.gtmMessages.days}-${value}`)}
        />
      </div>
    </div>
  )
}

import type { FC } from 'react'
import React from 'react'
import classNames from 'classnames'
import { Button } from '..'
import type { ICollapsingBlockViewProps } from './interfaces'
import './styles.scss'

const CollapsingBlockView: FC<ICollapsingBlockViewProps> = props => {
  const {
    id,
    title,
    titleId,
    children,
    headingClass = 'h5',
    className,
    contentClassName,
    animation,
    isOpenButtonOnLeft = false,
    buttonClassName,
    toggleClassName,
    isIconButtonWhite,
    isExpanded,
    onToggle,
  } = props

  const toggle = <span className={toggleClassName} />

  return (
    <div id={id} className={classNames(`collapsing-block`, className, isExpanded && `${className}--active`)} {...animation}>
      <Button
        modifiers={['clickable', 'full-width']}
        textId={titleId}
        className={classNames(
          {
            'collapsing-block__button-left': isOpenButtonOnLeft,
            'collapsing-block__button': !isOpenButtonOnLeft,
            'collapsing-block__button_active': isExpanded && !isOpenButtonOnLeft,
            'collapsing-block__button-left_active': isExpanded && isOpenButtonOnLeft,
            'collapsing-block__button-white': isIconButtonWhite,
          },
          buttonClassName,
          headingClass,
        )}
        onClick={onToggle}
        isUnstyled
      >
        {isOpenButtonOnLeft && toggle}
        {title}
        {!isOpenButtonOnLeft && toggle}
      </Button>
      <div className={classNames(contentClassName, 'collapsing-block-content', { 'collapsing-block-content--expanded': isExpanded })}>
        {children}
      </div>
    </div>
  )
}

export default CollapsingBlockView

import React from 'react'
import { CommunicationMethodSelector } from '@/components/composed/financing/CommunicationMethodSelector'
import type { ILoanProductOrderMethodCommunicationProps } from './types'
import './styles.scss'

export const LoanProductOrderMethodCommunication: React.FC<ILoanProductOrderMethodCommunicationProps> = props => {
  const { fundingRequestId, onSuccessUpdateFundingRequest, onErrorUpdateFundingRequest } = props

  return (
    <CommunicationMethodSelector
      className="loanProductOrderMethodCommunication"
      cardClassName="loanProductOrderMethodCommunication__card"
      fundingRequestId={fundingRequestId}
      onErrorUpdateFundingRequest={onErrorUpdateFundingRequest}
      onSuccessUpdateFundingRequest={onSuccessUpdateFundingRequest}
    />
  )
}

import React from 'react'
import { HeadingVariant } from '@/components/composed/ConsultingForm/constants'
import type {
  TChangeLoginDialogOpenHandler,
  TOpenRegistrationFormHandler,
  TSuccessFormSubmitHandler,
} from '@/components/composed/ConsultingForm/types'
import { addModalToWellKnown } from '@/components/composed/Modal/constants'
import { ConsultingFormModal } from '@/components/composed/modals'
import asyncEpics from '@/logic/financing/epics'
import { ApplyingFinancingOfferSuccessModal } from '@/pages/Financing/components'
import { addNewEpicsLazily } from '@/utils/addNewEpicsLazily'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import type { IFinancingConsultingFormProps } from './components/ConsultingForm/types'
import { ConsultingForm } from './components'
import './styles.scss'

addModalToWellKnown({ ApplyingFinancingOfferSuccessModal })
addModalToWellKnown({ ConsultingFormModal })
addNewEpicsLazily([asyncEpics])

type Props = {
  firstInputRef: NonNullable<IFinancingConsultingFormProps['firstInputRef']>
  onOpenRegistrationForm?: TOpenRegistrationFormHandler
  onChangeLoginDialogOpen?: TChangeLoginDialogOpenHandler
  onSuccessFormSubmit: TSuccessFormSubmitHandler
}

export const FinancingConsultingFormStep = ({
  firstInputRef,
  onOpenRegistrationForm,
  onChangeLoginDialogOpen,
  onSuccessFormSubmit,
}: Props) => {
  const { isMobile } = useDeviceType()

  return (
    <ConsultingForm
      firstInputRef={firstInputRef}
      headingVariant={!isMobile ? HeadingVariant.FORM_ON_MAIN_FINANCING_PAGE : HeadingVariant.FORM_ON_MAIN_FINANCING_PAGE_MOBILE}
      registrationFormClassName="financingConsultingFormStep__registrationForm"
      footerClassName="financingConsultingFormStep__footer"
      footerLinkClassName="color_green"
      onOpenRegistrationForm={onOpenRegistrationForm}
      onChangeLoginDialogOpen={onChangeLoginDialogOpen}
      onSuccessFormSubmit={onSuccessFormSubmit}
      shouldSkipSuccessModal
    />
  )
}

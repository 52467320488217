import type { AxiosError, AxiosInstance } from 'axios'
import axios from 'axios'
import type { IAxiosFactoryParams, RequestConfig, ResponseConfig } from '@/api/client/types'
import { getJwt } from '@/logic/auth/reducer'
import { reducerManager } from '@/redux/reducerManager'
import { AuthCookiesService } from '@/services'
import { ExecutionEnvironment } from '@/utils/ExecutionEnvironment'

export const createAxiosInstance = (params: IAxiosFactoryParams): AxiosInstance => {
  const { baseURL } = params
  //eslint-disable-next-line @typescript-eslint/no-explicit-any, unused-imports/no-unused-vars
  const { isSsr } = ExecutionEnvironment

  const instance = axios.create({
    baseURL,
    timeout: Number(process.env.API_RESPONSE_TIMEOUT) || 60_000,
  })

  instance.interceptors.request.use(config => {
    // TODO https://jira.poleinvest.ru/browse/OUT-1908
    const getState = reducerManager.store?.getState
    const token = AuthCookiesService.getJwt() || (getState && getState() !== undefined && getJwt()(getState()))

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  })

  return instance
}

export const createAxiosClient = (params: IAxiosFactoryParams) => {
  const { baseURL } = params
  const instance = createAxiosInstance({ baseURL })

  const client = async <TData, TError = unknown, TVariables = unknown>(
    config: RequestConfig<TVariables>,
  ): Promise<ResponseConfig<TData>> => {
    const promise = instance.request<TVariables, ResponseConfig<TData>>({ ...config }).catch((e: AxiosError<TError>) => {
      throw e
    })

    return promise
  }

  return client
}

import type { IFormWithLoanPurposeSelect } from '@/components/ui/fields/LoanPurposeSelect/types'
import type { IDadataSuggestionCity } from '@/components/ui/fields/SearchCitiesWithSuggestions/interfaces'
import type { IProfileAgrarian } from '@/logic/profile/interface'
import type { EFinancingOfferType, EFinancingProductId, ELoanProductName, ELoanProductOption, IPrice } from '@/types'

export interface IFinancingOfferFormValues extends IFormWithLoanPurposeSelect {
  regionCode?: number
  loanAmount: IPrice
  loanTerm: number
  phone: string
  organizationInn: string
  email: string
  city: IDadataSuggestionCity | string
  location: IDadataSuggestionCity
  comment: string
  agreement: boolean
  productId: EFinancingProductId
  contactPersonPosition?: string
  organizationName?: string
  cropsArea?: number
  desiredAdvancePaymentDate?: string
  grossHarvest: number
  bankTitle?: string
  iconUrl?: string
  lessor?: string
  regions?: number
  lost?: boolean
}

export type TGetApplyingFinancingOfferInitialValues = (options: {
  offerType: EFinancingOfferType
  productId: EFinancingProductId
  profile: IProfileAgrarian
  formValues?: Partial<IFinancingOfferFormValues>
  bankTitle?: string
  iconUrl?: string
  isLeasing?: boolean
}) => Partial<IFinancingOfferFormValues>

export interface IApplyingFinancingOfferProps {
  productId: EFinancingProductId
  offerType: EFinancingOfferType
  gtmId?: ELoanProductName | EFinancingProductId
}

export interface IApplyFinancingOffer {
  body: IApplyingFinancingOfferRequestBody
  offerType?: EFinancingOfferType
  isLeasing?: boolean
  isLost?: boolean
  skipSuccessModal?: boolean
  onBackAfterError?: () => void
  onSuccess?: (fundingRequestId: number) => void
}

export interface IApplyingFinancingOfferRequestBody {
  regionCode?: number
  loanAmount?: IPrice
  phone: string
  email?: string
  city?: string
  fiasCode?: string
  comment?: string
  agreement: boolean
  productId?: EFinancingProductId
  productIds?: EFinancingProductId[]
  contactPersonPosition?: string
  organizationName?: string
  organizationInn?: string
  cropsArea?: number
  desiredAdvancePaymentDate?: string
  grossHarvest?: number
  regions?: number
  lost?: boolean
}

export interface IApplyingFinancingOfferResponseBody {
  fundingRequests?: IApplyingFinancingOfferResponseData[]
  response_id?: string
  message?: string
}

export interface IApplyingFinancingOfferResponseData {
  id: number
  multiId: string
  agreement: boolean
  comment: string
  creationDate: string
  email: string
  fullName: string
  organizationInn: string
  organizationName: string
  phone: string
  productId: string
  updateDate: string
}

export interface IApplyingFinancingOfferSuccessModalOptions {
  prevFormValues: IFinancingOfferFormValues
  requestId?: number
}

export type TOpenErrorMessageModalByStatusOptions = {
  status?: string
  message?: string
  onBackAfterError?: () => void
}

export interface IRequestLoanProducts {
  regionCode: number
  options: ELoanProductOption[]
}

export interface IFinancingLoanProducts {
  compatibleProductIds: ELoanProductName[]
  otherProductsIds: ELoanProductName[]
}

export interface IInstallmentCalculatorForm {
  sum: number
  days: number
}

export interface IInstallmentCalculatorRequest {
  regionId: number
  sum: number
  days: number
  onlySber?: boolean
}

export enum ECalculatorIndex {
  DEFAULT = 'default',
}

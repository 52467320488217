import type { GetAuctionsForVitrinaQueryParams } from '@/api/kubik/auctions'
import {
  getAuctionsForVitrina,
  getAuctionsForVitrinaQueryKey,
  getAuctionV2,
  getAuctionV2QueryKey,
  getBetsV2,
  getBetsV2QueryKey,
} from '@/api/kubik/auctions'
import type { TSsrPrefetchQueries } from '@/interfaces'

export const betsVitrinaPrefetchSsrQueries: TSsrPrefetchQueries = (routeParams: Record<string, string | undefined>) => {
  const params: GetAuctionsForVitrinaQueryParams = {
    offset: routeParams?.offset ? Number(routeParams?.offset) : 0,
  }

  return [{ queryFn: () => getAuctionsForVitrina(params), queryKey: getAuctionsForVitrinaQueryKey(params) }]
}

export const betsAuctionPrefetchQueries: TSsrPrefetchQueries = (routeParams: Record<'id', string | undefined>) => {
  const params = routeParams.id ? { auctionId: routeParams.id } : { auctionId: '' }

  return [
    { queryFn: () => getAuctionV2(params), queryKey: getAuctionV2QueryKey(params) },
    { queryFn: () => getBetsV2({ auctionId: params.auctionId }), queryKey: getBetsV2QueryKey({ auctionId: params.auctionId }) },
  ]
}

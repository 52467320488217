import React from 'react'
import type { IAppFixedOverlayContainerProps } from './types'
import './styles.scss'

const AppFixedOverlayContainer = (props: IAppFixedOverlayContainerProps) => {
  const { children } = props

  return <aside className="appFixedOverlayContainer">{children}</aside>
}

export default AppFixedOverlayContainer

import type { FC } from 'react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { CommunicationMethodSelector } from '@/components/composed/financing/CommunicationMethodSelector'
import {
  ConsultingConditions,
  ConsultingHeader,
} from '@/components/composed/financing/FinancingConsulting/components/FinancingConsultingFormStep/components'
import type { IFinancingConsultingFormProps } from '@/components/composed/financing/FinancingConsulting/components/FinancingConsultingFormStep/components/ConsultingForm/types'
import type { TFinancingConsultingState } from '@/components/composed/financing/FinancingConsulting/types'
import { FinancingProductError } from '@/components/composed/financing/FinancingProductError'
import { FinancingProductSuccess } from '@/components/composed/financing/FinancingProductSuccess'
import { realRequestStatusName } from '@/constants/financing'
import type { IApplyingFinancingOfferResponseBody } from '@/logic/financing/interfaces'
import { getRequestData, getRequestState } from '@/logic/requestStatus/reducer'
import { navItems } from '@/pages/FinancingAgent/constants'
import type { RootState } from '@/redux/interfaces'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { FinancingConsultingFormStep } from './components'
import { EFinancingConsultingStep } from './constants'
import { messages } from './messages'
import './styles.scss'

const mapState = (state: RootState) => ({
  requestData: getRequestData(realRequestStatusName)(state),
  requestState: getRequestState(realRequestStatusName)(state),
})

const FinancingConsulting: FC = () => {
  const { isMobile } = useDeviceType()
  const firstInputRef = useRef() as NonNullable<IFinancingConsultingFormProps['firstInputRef']>
  const [state, setState] = useState<TFinancingConsultingState>({ step: EFinancingConsultingStep.FORM })
  const { requestState, requestData } = useSelector(mapState)

  const handleSetDefaultStep = () => {
    setState({ step: EFinancingConsultingStep.FORM })
  }

  const handleSuccessUpdateFundingRequest = () => {
    setState(previousState => {
      if (previousState.step === EFinancingConsultingStep.COMMUNICATION_METHOD) {
        return { ...previousState, step: EFinancingConsultingStep.SUCCESS }
      }

      return previousState
    })
  }

  const handleError = () => {
    setState({ step: EFinancingConsultingStep.ERROR })
  }

  const onHeaderClick = useCallback(() => {
    if (!firstInputRef.current || !isMobile) return

    firstInputRef.current.focus()
    firstInputRef.current.scrollIntoView({ block: 'nearest' })
  }, [firstInputRef, isMobile])

  const handleOpenRegistationForm = (phoneNumber: string) => {
    setState(previousState => {
      if (previousState.step === EFinancingConsultingStep.FORM) {
        return { ...previousState, step: EFinancingConsultingStep.REGISTRATION, phoneNumber }
      }

      return previousState
    })
  }

  const handleChangeLoginDialogOpen = (isOpen: boolean, phoneNumber: string) => {
    if (!isOpen) {
      return
    }

    setState(previousState => {
      if (previousState.step === EFinancingConsultingStep.FORM && isOpen) {
        return { ...previousState, step: EFinancingConsultingStep.LOGIN, phoneNumber }
      }

      if (previousState.step === EFinancingConsultingStep.LOGIN && !isOpen) {
        return { ...previousState, step: EFinancingConsultingStep.FORM, phoneNumber }
      }

      return previousState
    })
  }

  const handleSuccessFundingRequestSubmit = (fundingRequestId: number) => {
    setState(previousState => {
      if (previousState.step === EFinancingConsultingStep.FORM) {
        return { ...previousState, step: EFinancingConsultingStep.COMMUNICATION_METHOD, fundingRequestId }
      }

      return previousState
    })
  }

  useEffect(() => {
    const stepsToCheck = [EFinancingConsultingStep.REGISTRATION, EFinancingConsultingStep.LOGIN]

    if (stepsToCheck.includes(state.step)) {
      const firstFundingRequest = (requestData as IApplyingFinancingOfferResponseBody | undefined)?.fundingRequests?.[0]

      if (requestState.isLoaded && firstFundingRequest) {
        setState(previousState => {
          if (stepsToCheck.includes(previousState.step)) {
            return { ...previousState, step: EFinancingConsultingStep.COMMUNICATION_METHOD, fundingRequestId: firstFundingRequest.id }
          }

          return previousState
        })
      } else if (requestState.isFailure) {
        setState(previousState => {
          if (stepsToCheck.includes(previousState.step)) {
            return { ...previousState, step: EFinancingConsultingStep.ERROR }
          }

          return previousState
        })
      }
    }
  }, [requestData, requestState, state])

  const getActiveStep = () => {
    switch (state.step) {
      case EFinancingConsultingStep.FORM:
      case EFinancingConsultingStep.LOGIN:
      case EFinancingConsultingStep.REGISTRATION:
        return (
          <FinancingConsultingFormStep
            firstInputRef={firstInputRef}
            onOpenRegistrationForm={handleOpenRegistationForm}
            onChangeLoginDialogOpen={handleChangeLoginDialogOpen}
            onSuccessFormSubmit={handleSuccessFundingRequestSubmit}
          />
        )
      case EFinancingConsultingStep.COMMUNICATION_METHOD:
        return (
          <CommunicationMethodSelector
            className="financingConsulting__communicationMethod"
            titleClassName="financingConsulting__communicationMethodTitle"
            containerClassName="financingConsulting__communicationMethodContainer"
            buttonClassName="financingConsulting__communicationMethodButton"
            fundingRequestId={state.fundingRequestId}
            onSuccessUpdateFundingRequest={handleSuccessUpdateFundingRequest}
            onErrorUpdateFundingRequest={handleError}
          />
        )
      case EFinancingConsultingStep.SUCCESS:
        return (
          <FinancingProductSuccess
            className="financingConsulting__success"
            wrapperClassName="financingConsulting__successWrapper"
            subtitleClassName="financingConsulting__successSubtitle"
            titleClassName="financingConsulting__successTitle"
            itemClassName="financingConsulting__successItem"
            navClassName="financingConsulting__successNav"
            navIconClassName="financingConsulting__successNavIcon"
            subtitle={messages.success.subtitle}
            navItems={navItems}
            resetStep={handleSetDefaultStep}
            fundingRequestId={state.fundingRequestId}
          />
        )
      case EFinancingConsultingStep.ERROR:
        return <FinancingProductError className="financingConsulting__error" onResetStep={handleSetDefaultStep} />
      default:
        return null
    }
  }

  return (
    <div className="financingConsulting elevatableBlock">
      <div
        className={classNames('financingConsulting__info', {
          'financingConsulting__info--followingStep': state.step !== EFinancingConsultingStep.FORM,
        })}
      >
        <ConsultingHeader onClick={onHeaderClick} />
        <ConsultingConditions />
      </div>
      <div className="financingConsulting__form">{getActiveStep()}</div>
    </div>
  )
}

export default FinancingConsulting

import loadable from '@loadable/component'

export { default as Badge } from './Badge'

export { default as ConsultingForm } from './FinancingConsulting/components/FinancingConsultingFormStep/components/ConsultingForm'

export const FinancingConsulting = loadable(
  /* webpackChunkName: "FinancingConsulting" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./FinancingConsulting'),
)

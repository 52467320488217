import React from 'react'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'
import { useUpdateFundingRequest } from '@/api/kubik/credit'
import { Button, Image } from '@/components/ui'
import { RadioButton } from '@/components/ui/ReactHookFormFields'
import { ECommunicationMethod } from './constants'
import { messages } from './messages'
import type { ICommunicationMethodSelectorProps } from './types'
import './styles.scss'

export const CommunicationMethodSelector: React.FC<ICommunicationMethodSelectorProps> = props => {
  const {
    className,
    titleClassName,
    cardClassName,
    buttonClassName,
    fundingRequestId,
    containerClassName,
    onSuccessUpdateFundingRequest,
    onErrorUpdateFundingRequest,
  } = props

  const { control, getValues, watch } = useForm({
    defaultValues: {
      communicationMethod: ECommunicationMethod.PHONE,
    },
  })

  const communicationMethodField = watch('communicationMethod')

  const { mutateAsync: updateFundingRequest, isLoading } = useUpdateFundingRequest({
    mutation: { onSuccess: onSuccessUpdateFundingRequest, onError: onErrorUpdateFundingRequest },
  })

  const onSelectCommunicationMethod = async () => {
    await updateFundingRequest({ fundingRequestId, communicationMethod: getValues('communicationMethod') })
  }

  return (
    <div className={classNames(className, 'communicationMethodSelector')}>
      <h3 className={classNames(titleClassName, 'communicationMethodSelector__title')}>{messages.title}</h3>

      <div className={classNames(containerClassName, 'communicationMethodSelector__container')}>
        <div
          className={classNames(cardClassName, 'communicationMethodSelector__card', {
            communicationMethodSelector__card__selected: communicationMethodField === ECommunicationMethod.PHONE,
          })}
        >
          <Image className="communicationMethodSelector__image" src="financingOffer/agent/connections/phone.png" isInStatic />
          <RadioButton
            className="communicationMethodSelector__radioButton"
            name="communicationMethod"
            control={control}
            value={ECommunicationMethod.PHONE}
            CustomLabel={<h5>{messages.fields.communicationMethod.phone}</h5>}
          />
        </div>
        <div
          className={classNames(cardClassName, 'communicationMethodSelector__card', {
            communicationMethodSelector__card__selected: communicationMethodField === ECommunicationMethod.WHATSAPP,
          })}
        >
          <Image className="communicationMethodSelector__image" src="financingOffer/agent/connections/whatsapp.png" isInStatic />
          <RadioButton
            className="communicationMethodSelector__radioButton"
            name="communicationMethod"
            control={control}
            value={ECommunicationMethod.WHATSAPP}
            CustomLabel={<h5>{messages.fields.communicationMethod.whatsapp}</h5>}
          />
        </div>
      </div>

      <div className="communicationMethodSelector__actions">
        <Button
          className={buttonClassName}
          type="submit"
          modifiers={['green']}
          loaderProps={{ isVisible: isLoading }}
          onClick={onSelectCommunicationMethod}
        >
          {messages.actions.select}
        </Button>
      </div>
    </div>
  )
}

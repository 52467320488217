import { EAgriculturalProductsRoute, EFinancingOfferRoute, EFinancingOfferType, EFinancingProductId, ELoanProductName } from '@/types'
import { messages } from './messages'
import type { ILoanProduct, ILoanProductBrand } from './types'
import { ELoanProductActionType, ELoanProductBrand } from './types'

const LOAN_PRODUCT_BRAND_LOGO_FOLDER = 'loanProducts/brand/'

export const LOAN_PRODUCT_BRAND_BY_NAME: Readonly<Record<ELoanProductBrand, ILoanProductBrand>> = {
  [ELoanProductBrand.AGROINTEGRATOR]: {
    name: ELoanProductBrand.AGROINTEGRATOR,
    logo: `${LOAN_PRODUCT_BRAND_LOGO_FOLDER}agrointegrator`,
  },
  [ELoanProductBrand.SBER_BUSINESS]: {
    name: ELoanProductBrand.SBER_BUSINESS,
    logo: `${LOAN_PRODUCT_BRAND_LOGO_FOLDER}sberBusiness`,
  },
  [ELoanProductBrand.POLE_RF]: {
    name: ELoanProductBrand.POLE_RF,
    logo: `${LOAN_PRODUCT_BRAND_LOGO_FOLDER}poleRf`,
  },
  [ELoanProductBrand.DEMETRA]: {
    name: ELoanProductBrand.DEMETRA,
    logo: `${LOAN_PRODUCT_BRAND_LOGO_FOLDER}demetra`,
  },
  [ELoanProductBrand.CENTER_INVEST]: {
    name: ELoanProductBrand.CENTER_INVEST,
    logo: `${LOAN_PRODUCT_BRAND_LOGO_FOLDER}centerInvest`,
  },
  [ELoanProductBrand.ALFA]: {
    name: ELoanProductBrand.ALFA,
    logo: `${LOAN_PRODUCT_BRAND_LOGO_FOLDER}alfa`,
  },
  [ELoanProductBrand.MANDARIN]: {
    name: ELoanProductBrand.MANDARIN,
    logo: `${LOAN_PRODUCT_BRAND_LOGO_FOLDER}mandarin`,
  },
  [ELoanProductBrand.POLE_FINANCE]: {
    name: ELoanProductBrand.POLE_FINANCE,
    logo: `${LOAN_PRODUCT_BRAND_LOGO_FOLDER}poleFinance`,
  },
  [ELoanProductBrand.INTERLEASING]: {
    name: ELoanProductBrand.INTERLEASING,
    logo: `${LOAN_PRODUCT_BRAND_LOGO_FOLDER}interleasing`,
  },
  [ELoanProductBrand.RAL]: {
    name: ELoanProductBrand.RAL,
    logo: `${LOAN_PRODUCT_BRAND_LOGO_FOLDER}rosagroleasing`,
  },
  [ELoanProductBrand.FLEET_FINANCE]: {
    name: ELoanProductBrand.FLEET_FINANCE,
    logo: `${LOAN_PRODUCT_BRAND_LOGO_FOLDER}fleetFinanceLeasing`,
  },
}

export const LOAN_PRODUCTS: Partial<Record<string, ILoanProduct>> = {
  [ELoanProductName.BNPL_POLECREDIT]: {
    name: 'expressMfo',
    offerType: EFinancingOfferType.BNPL_POLECREDIT,
    productId: EFinancingProductId.BNPL_POLECREDIT,
    gtmId: ELoanProductName.BNPL_POLECREDIT,
    brand: LOAN_PRODUCT_BRAND_BY_NAME.poleFinance,
    url: EFinancingOfferRoute.MFOPage,
    actionType: ELoanProductActionType.COLLAPSING_BLOCK,
  },
  [ELoanProductName.BNPL_AGROINTEGRATOR]: {
    name: 'bnplAgrointegrator',
    offerType: EFinancingOfferType.BNPL_AGROINTEGRATOR,
    productId: EFinancingProductId.BNPL_AGROINTEGRATOR,
    gtmId: ELoanProductName.BNPL_AGROINTEGRATOR,
    brand: LOAN_PRODUCT_BRAND_BY_NAME.poleRf,
    url: EAgriculturalProductsRoute.main,
    actionType: ELoanProductActionType.INSTALLMENT_LINK,
    hasNoHeaderLink: true,
  },
  [ELoanProductName.SBERPAY]: {
    name: 'sberpay',
    offerType: EFinancingOfferType.SBERPAY,
    productId: EFinancingProductId.SBERPAY,
    gtmId: ELoanProductName.SBERPAY,
    brand: LOAN_PRODUCT_BRAND_BY_NAME.sberBusiness,
    url: EFinancingOfferRoute.sberRassrochka,
    actionType: ELoanProductActionType.COLLAPSING_BLOCK,
  },
  [ELoanProductName.EXPRESS_MFO]: {
    name: 'expressMfo',
    offerType: EFinancingOfferType.EXPRESS_MFO,
    productId: EFinancingProductId.EXPRESS_MFO,
    gtmId: ELoanProductName.EXPRESS_MFO,
    brand: LOAN_PRODUCT_BRAND_BY_NAME.poleFinance,
    url: EFinancingOfferRoute.MFOPage,
    actionType: ELoanProductActionType.COLLAPSING_BLOCK,
  },
  [ELoanProductName.SBER_OBOROTKA_LOAN]: {
    name: 'sberOborotkaLoan',
    offerType: EFinancingOfferType.REVOLVING_LOAN_SBER,
    productId: EFinancingProductId.REVOLVING_LOAN_SBER,
    gtmId: ELoanProductName.SBER_OBOROTKA_LOAN,
    brand: LOAN_PRODUCT_BRAND_BY_NAME.sberBusiness,
    url: EFinancingOfferRoute.oborotSber,
    actionType: ELoanProductActionType.COLLAPSING_BLOCK,
  },
  [ELoanProductName.ADVANCE_FUNDING]: {
    name: 'advanceFunding',
    offerType: EFinancingOfferType.ADVANCE_FUNDING,
    productId: EFinancingProductId.ADVANCE_FUNDING,
    gtmId: ELoanProductName.ADVANCE_FUNDING,
    brand: LOAN_PRODUCT_BRAND_BY_NAME.demetra,
    url: EFinancingOfferRoute.advancePaymentOnFutureHarvest,
    actionType: ELoanProductActionType.COLLAPSING_BLOCK,
  },
  [ELoanProductName.SBER_INVESTMENT_LOAN]: {
    name: 'sberInvestmentLoan',
    offerType: EFinancingOfferType.SBER_INVESTMENT_LOAN,
    productId: EFinancingProductId.SBER_INVESTMENT_LOAN,
    gtmId: ELoanProductName.SBER_INVESTMENT_LOAN,
    brand: LOAN_PRODUCT_BRAND_BY_NAME.sberBusiness,
    url: EFinancingOfferRoute.investSber,
    actionType: ELoanProductActionType.COLLAPSING_BLOCK,
  },
  [ELoanProductName.CENTER_INVEST_LOAN]: {
    name: 'centerInvestLoan',
    offerType: EFinancingOfferType.CENTER_INVEST_LOAN,
    productId: EFinancingProductId.CENTER_INVEST_LOAN,
    gtmId: ELoanProductName.CENTER_INVEST_LOAN,
    brand: LOAN_PRODUCT_BRAND_BY_NAME.centerInvest,
    url: EFinancingOfferRoute.centerInvestLoan,
    actionType: ELoanProductActionType.COLLAPSING_BLOCK,
  },
  // Данный вид кредита временно отключен
  [ELoanProductName.LOMBARD_LOAN_ALFA_BANK]: {
    name: 'lombardLoanAlfaBank',
    offerType: EFinancingOfferType.LOMBARD_LOAN_ALFA_BANK,
    productId: EFinancingProductId.LOMBARD_LOAN_ALFA_BANK,
    gtmId: ELoanProductName.LOMBARD_LOAN_ALFA_BANK,
    brand: LOAN_PRODUCT_BRAND_BY_NAME.alfa,
    url: EFinancingOfferRoute.lombardCreditAlfaBank,
    isHidden: true,
    actionType: ELoanProductActionType.ORDER_MODAL,
  },
  [ELoanProductName.BNPL_MANDARIN]: {
    name: 'bnplMandarin',
    offerType: EFinancingOfferType.BNPL_MANDARIN,
    productId: EFinancingProductId.BNPL_MANDARIN,
    gtmId: ELoanProductName.BNPL_MANDARIN,
    brand: LOAN_PRODUCT_BRAND_BY_NAME.mandarin,
    tooltip: messages.tooltip.mandarin,
    hasConsulting: true,
    actionType: ELoanProductActionType.ORDER_MODAL,
  },

  /// Лизинги
  [EFinancingProductId.SBER_LEASING]: {
    name: 'sberLeasing',
    offerType: EFinancingOfferType.SBER_LEASING,
    productId: EFinancingProductId.SBER_LEASING,
    gtmId: EFinancingProductId.SBER_LEASING,
    brand: LOAN_PRODUCT_BRAND_BY_NAME.sberBusiness,
    actionType: ELoanProductActionType.INSTALLMENT_LINK,
    url: EFinancingOfferRoute.sberLeasing,
  },
  [EFinancingProductId.LEASING_INTERLEASING]: {
    name: 'interleasing',
    offerType: EFinancingOfferType.LEASING_INTERLEASING,
    productId: EFinancingProductId.LEASING_INTERLEASING,
    gtmId: EFinancingProductId.LEASING_INTERLEASING,
    brand: LOAN_PRODUCT_BRAND_BY_NAME.interleasing,
    actionType: ELoanProductActionType.INSTALLMENT_LINK,
    url: EFinancingOfferRoute.interleasing,
  },
  [EFinancingProductId.LEASING_RAL]: {
    name: 'rosagroLeasing',
    offerType: EFinancingOfferType.LEASING_RAL,
    productId: EFinancingProductId.LEASING_RAL,
    gtmId: EFinancingProductId.LEASING_RAL,
    brand: LOAN_PRODUCT_BRAND_BY_NAME.rosagroleasing,
    actionType: ELoanProductActionType.INSTALLMENT_LINK,
    url: EFinancingOfferRoute.agriculturalMachinesAndEquipmentLeasing,
  },
  [EFinancingProductId.LEASING_FLEET_FINANCE]: {
    name: 'fleetFinanceLeasing',
    offerType: EFinancingOfferType.LEASING_FLEET_FINANCE,
    productId: EFinancingProductId.LEASING_FLEET_FINANCE,
    gtmId: EFinancingProductId.LEASING_FLEET_FINANCE,
    brand: LOAN_PRODUCT_BRAND_BY_NAME.fleetFinanceLeasing,
    actionType: ELoanProductActionType.INSTALLMENT_LINK,
    url: EFinancingOfferRoute.fleetFinanceLeasing,
  },
}

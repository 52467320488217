import isNil from 'lodash/isNil'
import type { Dispatch } from 'redux'
import { openModal } from '@/components/composed/Modal/actions'
import { applyFinancingOffer } from '@/logic/financing/actions'
import type { IApplyingFinancingOfferRequestBody, IFinancingOfferFormValues } from '@/logic/financing/interfaces'
import { EFinancingOfferType, EFinancingProductId } from '@/types'
import { messages } from './messages'

type TFormattingMFOFinancingOfferOptions = {
  regionsAvailableExpressMFO: number[]
  body: IApplyingFinancingOfferRequestBody | IFinancingOfferFormValues
  isAuthenticated?: boolean
  shouldSkipSuccessModal?: boolean
  onBackAfterError?: () => void
  dispatch: Dispatch
}

export const formattingMFOFinancingOffer = ({
  regionsAvailableExpressMFO,
  body,
  dispatch,
  shouldSkipSuccessModal,
  isAuthenticated = true,
}: TFormattingMFOFinancingOfferOptions) => {
  const { regions } = body
  const hasAvailableExpressMFO = !isNil(regions) && regionsAvailableExpressMFO.includes(regions)

  if (hasAvailableExpressMFO)
    dispatch(
      applyFinancingOffer({
        body: {
          ...body,
          productId: EFinancingProductId.EXPRESS_MFO,
          regionCode: regions,
          city: '',
        },
        isLost: !isAuthenticated,
        offerType: EFinancingOfferType.EXPRESS_MFO,
        skipSuccessModal: shouldSkipSuccessModal,
      }),
    )

  if (!hasAvailableExpressMFO)
    dispatch(
      openModal({
        options: {
          header: messages.title,
          dialogId: 'MessageModal',
        },
        contentOptions: {
          messages: [{ text: messages.notAvailable }],
        },
      }),
    )
}

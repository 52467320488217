import type { FC } from 'react'
import React, { useState } from 'react'
import CollapsingBlockView from '@/components/ui/CollapsingBlock/CollapsingBlockView'
import type { ICollapsingBlockProps } from './interfaces'
import './styles.scss'

const CollapsingBlock: FC<ICollapsingBlockProps> = props => {
  const { expandedOnMount, onToggle, ...otherProps } = props

  const [isExpanded, setIsExpanded] = useState(Boolean(expandedOnMount))

  const onToggleCollapsingBlock = () => {
    const newValue = !isExpanded

    onToggle?.(newValue)
    setIsExpanded(newValue)
  }

  return <CollapsingBlockView {...otherProps} isExpanded={isExpanded} onToggle={onToggleCollapsingBlock} />
}

export default CollapsingBlock

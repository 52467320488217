import client from "@/api/client/auctions";
import type { ResponseConfig } from "@/api/client/auctions";
import type { GetBetsV2QueryResponse, GetBetsV2QueryParams } from "../../types/GetBetsV2";

/**
     * @description Метод предназначен для получения данных по ставкам аукциона по ID аукциона. https://confluence.poleinvest.ru/pages/viewpage.action?pageId=448006637
    
     * @link /v2/auction/view_bets
     */
export async function getBetsV2 (params: GetBetsV2QueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetBetsV2QueryResponse>["data"]> {
    const { data: resData } = await client<GetBetsV2QueryResponse>({
        method: "get",
        url: `/v2/auction/view_bets`,
        params,
        ...options
    });
    
    return resData;
};
import React, { useCallback, useRef } from 'react'
import type { FieldPath, FieldValues } from 'react-hook-form'
import { useController } from 'react-hook-form'
import { SliderValueMoney } from '@frontend/pole-ui/lib/components/SliderValueMoney'
import { splitHookFormControllerProps } from '@/utils/fields'
import type { ISliderValueMoneyProps } from './types'

export function HookFormSliderValueMoney<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: ISliderValueMoneyProps<TFieldValues, TName>) {
  const [controllerProps, { onBlur, onChange, onFocus, ...componentProps }] = splitHookFormControllerProps(props)

  const inputRef = useRef<HTMLInputElement | null>(null)

  const { field } = useController({ ...controllerProps })

  const onInputBlur: NonNullable<typeof onBlur> = useCallback(() => {
    field.onBlur()
    onBlur?.()

    const minimumAllowedValue = componentProps.settings?.min ?? 0
    const maximumAllowedValue = componentProps.settings?.max ?? 0
    const currentValue = field.value

    if (currentValue < minimumAllowedValue) {
      field.onChange(minimumAllowedValue)
    }

    if (currentValue > maximumAllowedValue) {
      field.onChange(maximumAllowedValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, onBlur])

  const onInputChange: NonNullable<typeof onChange> = useCallback(
    event => {
      field.onChange(event)
    },
    [field],
  )

  const onInputFocus: NonNullable<typeof onFocus> = useCallback(() => {
    inputRef.current?.focus()
    onFocus?.()
  }, [onFocus])

  return <SliderValueMoney {...componentProps} {...field} onBlur={onInputBlur} onChange={onInputChange} onFocus={onInputFocus} />
}

import client from "@/api/client/auctions";
import type { ResponseConfig } from "@/api/client/auctions";
import type { GetAuctionV2QueryResponse, GetAuctionV2QueryParams } from "../../types/GetAuctionV2";

/**
     * @description Метод предназначен для получения данных аукциона по ID аукциона. https://confluence.poleinvest.ru/pages/viewpage.action?pageId=425482468
    
     * @link /v2/auction
     */
export async function getAuctionV2 (params: GetAuctionV2QueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetAuctionV2QueryResponse>["data"]> {
    const { data: resData } = await client<GetAuctionV2QueryResponse>({
        method: "get",
        url: `/v2/auction`,
        params,
        ...options
    });
    
    return resData;
};
import type { TMessages } from '@/types/TMessages'

export const messages = {
  header: {
    title: 'Зарабатывайте вместе с <Highlighted>ПолеФинанс</Highlighted>',
    button: 'Стать агентом',
    additionalButton: 'Калькулятор дохода',
    description: 'Дополнительный доход без отрыва от основной работы\nПривлекайте заявки на займы и получайте процент от выдач',
    details: {
      '1': { value: 'до 200 млн ₽', sign: 'размер кредита' },
      '2': { value: 'Без ежемесячных платежей', sign: 'Порядок погашения' },
      '3': { value: 'до 12 месяцев', sign: 'срок кредитования' },
    },
  },

  details: {
    list: {
      reward: {
        label: 'Вознаграждение агента - 0,5% от суммы выданного займа',
        value: 'наши агенты зарабатывают в среднем от 75 000 руб./мес.',
      },
      approval: {
        label: 'Вероятность одобрения до 83 %',
        value: 'гарантирует высокий доход (вероятность одобрения в банках ограничена 20%)',
      },
      pay: {
        label: 'Получайте доход быстро и стабильно',
        value: 'скорость выдачи денег клиентам - 5 рабочих дней, ежемесячная выплата вознаграждения',
      },
      income: {
        label: 'Дополнительный доход без усилий',
        value: 'привлекайте заявки на займы от ПолеФинанс - мы сами займемся продажей и сопровождением',
      },
      education: {
        label: 'Бесплатное обучение и маркетинговые материалы',
        value: 'по продукту ПолеФинанс',
      },
      status: {
        label: 'Отслеживайте статус ваших заявок',
        value: 'в личном кабинете агента',
      },
      available: {
        label: 'Доступно для всех',
        value: 'достаточно пройти обучение и подписать агентсткий договор',
      },
    },
  },

  intresting_details: {
    list: {
      repayment: {
        value: 'Погашение займа одним платежом в конце срока, после продажи урожая',
      },
      registration: {
        value: 'Простая процедура оформления и минимальный пакет документов',
      },
      refinancing: {
        value: 'Подходит для рефинансирования кредитов или первоначального взноса по лизингу',
      },
      early_repayment: {
        value: 'Досрочное погашение без штрафов и пеней',
      },
      approval: {
        value: 'Вероятность одобрения фермерам до 83%',
      },
      crediting: {
        value: 'Зачисление на счет в любом банке, без дополнительных комиссий и услуг',
      },
    },
    title: 'Почему продукт <Highlighted>ПолеФинанс</Highlighted> интересен рынку?',
  },

  steps: {
    title: '<Highlighted>Этапы</Highlighted> присоединения',
    '1': {
      title: 'Заполните заявку на присоединение к агентской сети ПолеФинанс',
    },
    '2': {
      title: 'Подпишите договор и пройдите обучение',
    },
    '3': {
      title: 'Получите доступ в личный кабинет и получайте доход',
    },
    button: 'Подать заявку',
  },

  benefits: {
    title: '<Highlighted>Преимущества</Highlighted> продукта',
    items: {
      simpleProcedure: 'Простая процедура оформления и минимальный пакет документов',
      suitedFor: 'Подходит для рефинансирования кредитов или первоначального взноса по лизингу',
      earlyRepayment: 'Досрочное погашение без штрафов и пеней',
      replaymentAtEndOfTerm: 'Погашение займа одним платежом в конце срока, после продажи урожая',
      probabilityOfApproval: 'Вероятность одобрения фермерам до 83%',
      transferToAnyBank: 'Зачисление на счет в любом банке, без дополнительных комиссий и услуг',
    },
  },

  howworks: {
    title: 'Как работает программа',
  },

  faq: {
    title: 'Часто задаваемые <Highlighted>вопросы</Highlighted>',
    questions: {
      howBeAgent: {
        title: 'Как стать агентом?',
        answer: `
            <p>Заполните простую <OpenConsultingForm>форму заявки</OpenConsultingForm> и наш менеджер свяжется с вами для дальнейшего оформления.</p>
        `,
      },
      whoCanBeAgent: {
        title: 'Кто может стать агентом?',
        answer:
          '<p>Агентами могут стать как физические лица, так и самозанятые, ИП и юридические лица. Можно совмещать с основной работой.</p>',
      },
      paymentOfRemuneration: {
        title: 'Как происходит выплата вознаграждения?',
        answer:
          '<p>Вы получаете 0,5% от суммы выданного займа. Выплаты осуществляются ежемесячно, по реквизитам, указанным в агентском договоре.</p>',
      },
      minQuantity: {
        title: 'Есть ли минимальные требования по количеству привлеченных клиентов?',
        answer: '<p>Нет, вы можете привлекать любое количество клиентов и зарабатывать без ограничений.</p>',
      },
      trackResults: {
        title: 'Как я могу отслеживать свои результаты?',
        answer: `<p>В личном кабинете вы сможете видеть все свои привлеченные заявки и размер начисленного вознаграждения.</p>`,
      },
      speciaeEducation: {
        title: 'Нужно ли иметь специальное образование или опыт работы?',
        answer: '<p>Нет, мы предоставляем все необходимое обучение и поддержку.</p>',
      },
    },
  },
  form: {
    title: 'Заполните форму и начните зарабатывать вместе с <Highlighted>поле.рф</Highlighted>',
    description:
      'После отправки заявки в течение часа вам перезвонит финансовый менеджер <Highlighted>поле.рф</Highlighted> для уточнения запроса',
  },
  breadcrumbs: {
    currentPage: 'Зарабатывайте вместе с ПолеФинанс',
  },
} satisfies TMessages

import React from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import NavSuccessNew from '@/components/composed/landingBlocks/LandingApplyingOfferFormNew/components/ApplyingFinancingAgentSuccessNew/components/NavSuccessNew/NavSuccessNew'
import { Button, GlassMorphIcon } from '@/components/ui'
import { EUserProfileRoute } from '@/types'
import { createRouterLink } from '@/utils/url'
import { messages } from './messages'
import type { IFinancingProductSuccessProps } from './types'
import './styles.scss'

export const FinancingProductSuccess: React.FC<IFinancingProductSuccessProps> = props => {
  const {
    resetStep,
    fundingRequestId,
    navItems,
    subtitle,
    className,
    wrapperClassName,
    subtitleClassName,
    navClassName,
    navIconClassName,
    itemClassName,
    titleClassName,
    withLogo,
  } = props

  const history = useHistory()

  const onClickToOrderPage = () => history.push(createRouterLink(EUserProfileRoute.fundingRequest, { id: String(fundingRequestId) }))

  return (
    <div className={classNames(className, 'financingProductSuccess')}>
      <div className={classNames(wrapperClassName, 'financingProductSuccess__wrapper')}>
        <div className="financingProductSuccess__header">
          {withLogo && <GlassMorphIcon image="success" className="financingProductSuccess__icon" />}

          <h3 className={classNames(titleClassName, 'financingProductSuccess__title')}>{messages.title}</h3>
          <div className={classNames(subtitleClassName, 'financingProductSuccess__subtitle')}>{subtitle ?? messages.subtitle}</div>
        </div>

        <div className="financingProductSuccess__main">
          <NavSuccessNew className={navClassName} classNameIcon={navIconClassName} navItems={navItems} itemClassName={itemClassName} />
        </div>
        <div className="financingProductSuccess__footer">
          <div className="financingProductSuccess__actions">
            <Button modifiers={['outline-gray', 'full-width-mobile']} onClick={resetStep}>
              {messages.actions.close}
            </Button>
            <Button modifiers={['green', 'full-width-mobile']} onClick={onClickToOrderPage}>
              {messages.actions.goToOrder}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

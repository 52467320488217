import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { CalculateRateSberMutationRequest, CalculateRateSberMutationResponse, CalculateRateSber400, CalculateRateSber401, CalculateRateSber403, CalculateRateSber422, CalculateRateSber429, CalculateRateSber500 } from "../types/CalculateRateSber";

/**
 * @link /api/credit-flow-backend/v1/installment/get_calculate-payment-amount-sber
 */

export function useCalculateRateSber <TData = CalculateRateSberMutationResponse, TError = CalculateRateSber400 | CalculateRateSber401 | CalculateRateSber403 | CalculateRateSber422 | CalculateRateSber429 | CalculateRateSber500, TVariables = CalculateRateSberMutationRequest>(options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/api/credit-flow-backend/v1/installment/get_calculate-payment-amount-sber`,
        data,
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};

import React, { useEffect, useRef } from 'react'
import { useRouteMatch } from 'react-router'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import { TG_URL } from '@/components/composed/TelegramSubscribeOverlayBanner/constants'
import { useBannerStore } from '@/components/composed/TelegramSubscribeOverlayBanner/store/useBannerStore'
import { Button, Image } from '@/components/ui'
import { MATCH_MEDIA_QUERIES } from '@/constants/common'
import { EJournalRoute } from '@/types'
import { getStaticImagePath } from '@/utils/images'
import { messages } from './messages'
import './styles.scss'

export function TelegramSubscribeOverlayBanner() {
  const nodeRef = useRef<HTMLDivElement>(null)

  const { setBannerShown, isShown, isClosed, setBannerClosed } = useBannerStore()

  const match = useRouteMatch([
    EJournalRoute.PUBLICATION_ITEM,
    EJournalRoute.NEWS,
    EJournalRoute.SEARCH,
    EJournalRoute.TAG,
    EJournalRoute.RUBRIC,
    EJournalRoute.MAIN,
  ])

  const setAnimation = (type: 'exiting' | 'entering') => {
    requestAnimationFrame(() => {
      if (nodeRef.current) nodeRef.current.className = `telegramSubscribeOverlayBanner__container telegramSubscribeOverlayBanner--${type}`
    })
  }

  const onBannerClose = () => {
    setAnimation('exiting')
    setBannerClosed()
  }

  useEffect(() => {
    if (!match?.isExact && isShown) setAnimation('exiting')

    if (!match?.isExact || isClosed) return

    const timeout = setTimeout(() => {
      setBannerShown(true)
    }, 12000)

    return () => clearTimeout(timeout)
  }, [match, isShown, setBannerShown, isClosed])

  const onAnimationEnd = (event: React.AnimationEvent<HTMLDivElement>) => {
    if (event.animationName === 'exiting') setBannerShown(false)
  }

  const onImageLoad = () => {
    if (nodeRef.current?.className.includes('--entering')) return

    setAnimation('entering')
  }

  return isShown ? (
    <div className="telegramSubscribeOverlayBanner__container" ref={nodeRef} onAnimationEnd={onAnimationEnd}>
      <picture>
        <source srcSet={getStaticImagePath('index/telegramBanner/background-mob.png')} media={MATCH_MEDIA_QUERIES.mobile} />
        <Image
          className={'telegramSubscribeOverlayBanner__backgroundImage'}
          src={'/index/telegramBanner/background-web.png'}
          shouldUseExactSrc
          isInStatic
          onLoad={onImageLoad}
        />
      </picture>
      <div className="telegramSubscribeOverlayBanner__innerContainer">
        <div className={'telegramSubscribeOverlayBanner__tgIcon'} />
        <Button className="telegramSubscribeOverlayBanner__icon" isUnstyled onClick={onBannerClose} iconProps={{ name: EIconName.Exit }} />

        <h4 className={'telegramSubscribeOverlayBanner__header'}>{messages.bannerHeader}</h4>
        <p className={'telegramSubscribeOverlayBanner__text text_small'}>{messages.bannerText}</p>

        <CommonLink
          className={'button button_small button_white telegramSubscribeOverlayBanner__actionButton'}
          url={TG_URL}
          onClick={onBannerClose}
        >
          Подписаться
        </CommonLink>
      </div>
    </div>
  ) : null
}

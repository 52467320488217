import React, { useMemo } from 'react'
import ReactHintFactory from 'react-hint'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { Toaster } from '@frontend/pole-ui/lib/components/Notification'
import { IntlProvider } from 'rsuite'
import ru from 'rsuite/lib/IntlProvider/locales/ru_RU'
import { AppAccessControlProvider } from '@/App/components/AppAccessControlProvider/AppAccessControlProvider'
import { useOnAppMount } from '@/App/hooks/useOnAppMount'
import { TelegramSubscribeOverlayBanner } from '@/components/composed/TelegramSubscribeOverlayBanner/TelegramSubscrbeOverlayBanner'
import { getAuthState } from '@/logic/auth/reducer'
import { Redirect404 } from '@/router/components'
import { useLocation } from '@/router/hooks'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import 'cross-fetch/polyfill'
import messages from '../commonMessages/messages.json'
import { CookieAgreement, Footer, Header, MobileNavBar, Modal, UserRegionDefiner } from '../components/composed'
import { Breadcrumbs } from '../components/ui'
import { hasWebpSupport, is404Page } from '../logic/app/reducer'
import { getCurrentLocale } from '../logic/translation/selectors'
import { injectMessages } from '../logic/translation/utils'
import type { RootState } from '../redux/interfaces'
import { ROUTES } from '../routes'
import { useFormatDate } from '../utils/hooks/format/useFormatDate'
import AppFixedOverlayContainer from './components/AppFixedOverlayContainer/AppFixedOverlayContainer'
import { AppRoute } from './components'
import 'swiper/swiper.scss'
import 'keen-slider/keen-slider.min.css'
import 'rsuite/dist/styles/rsuite-default.min.css'
import 'react-hint/css/index.css'
import './app.scss'

const ReactHint = ReactHintFactory(React)

const Routes = React.memo(() => (
  <Switch>
    {ROUTES.map(routeProps => (
      <AppRoute {...routeProps} />
    ))}
    <Route key="404" component={() => <Redirect404 />} />
  </Switch>
))

const RsuiteLocale: Record<string, Object> = {
  ru,
}

injectMessages(messages)

const mapState = (state: RootState) => ({
  auth: getAuthState()(state),
  currentLanguage: getCurrentLocale()(state),
  withWebp: hasWebpSupport()(state),
  is404: is404Page()(state),
})

const App: React.FC = () => {
  const { pathname } = useLocation()
  const { currentLanguage, withWebp, is404, auth } = useSelector(mapState)
  const { formatDate } = useFormatDate()

  useOnAppMount()

  const { isDesktop, isMobile } = useDeviceType()

  const accessControlContext = useMemo(
    () => ({
      auth,
    }),
    [auth],
  )

  return (
    <IntlProvider locale={RsuiteLocale[currentLanguage]} formatDate={(date, dateFormat) => formatDate(date, dateFormat)}>
      <AppAccessControlProvider context={accessControlContext}>
        <Modal />
        <ReactHint autoPosition events />
        <div className={`app app_${withWebp ? 'with-webp' : 'no-webp'}`}>
          <UserRegionDefiner />
          <AppFixedOverlayContainer>
            <TelegramSubscribeOverlayBanner />
            <CookieAgreement />
          </AppFixedOverlayContainer>
          <Header />
          {!isDesktop && <MobileNavBar />}
          <main data-page={pathname}>
            {!is404 && !isMobile && (
              <div className="page-wrapper">
                <Breadcrumbs />
              </div>
            )}
            <Routes />
          </main>
          {!is404 && <Footer />}
          <Toaster />
        </div>
      </AppAccessControlProvider>
    </IntlProvider>
  )
}

export default App

import React from 'react'
import { useIntl } from 'react-intl'
import { EPriceCurrency } from '@/types'
import { formatDate } from '@/utils/formatUtils'
import { formatMoney } from '@/utils/price'
import type { IHistoryListProps } from './interfaces'
import { messages } from './messages'
import './styles.scss'

const HistoryList: React.FC<IHistoryListProps> = ({ history = [] }) => {
  const intl = useIntl()
  const { formatNumber } = useIntl()

  return (
    <ul className="historyList">
      {history.map((item, key) => {
        const { message, initiator, finalPaidAmount, finalPaidVolume, date } = item
        if (!message || message.includes('TEMPLATE NOT FOUND - ')) return null

        return (
          <li className="historyItem" key={key}>
            <div className="historyItem__text">{message}</div>
            <time className="historyItem__date" dateTime={formatDate(date)}>
              {formatDate(date, 'HH:mm  dd.MM.yyyy')}
            </time>
            <div className="historyItem__details">
              {initiator && <div className="initiator">{initiator}</div>}
              {finalPaidAmount && (
                <div className="amount">
                  <span className="amount__label">{messages.finalPaidAmount}: </span>
                  <span className="amount__value">
                    {formatMoney(finalPaidAmount, intl)} {EPriceCurrency.RUB}
                  </span>
                </div>
              )}
              {finalPaidVolume && (
                <div className="amount">
                  <span className="amount__label">{messages.finalPaidVolume}: </span>
                  <span className="amount__value">
                    {formatNumber(finalPaidVolume)} {intl.formatMessage({ id: 'global.units.tonnShort' })}
                  </span>
                </div>
              )}
            </div>
          </li>
        )
      })}
    </ul>
  )
}

export default HistoryList

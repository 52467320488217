import React from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import NavSuccessNew from '@/components/composed/landingBlocks/LandingApplyingOfferFormNew/components/ApplyingFinancingAgentSuccessNew/components/NavSuccessNew/NavSuccessNew'
import { Button, GlassMorphIcon } from '@/components/ui'
import { EUserProfileRoute } from '@/types'
import { createRouterLink } from '@/utils/url'
import { messages } from './messages'
import type { ILoanProductOrderSuccessProps } from './types'
import './styles.scss'

export const LoanProductOrderSuccess: React.FC<ILoanProductOrderSuccessProps> = props => {
  const {
    resetStep,
    fundingRequestId,
    navItems,
    subtitle,
    className,
    wrapperClassName,
    subtitleClassName,
    cardClassName,
    titleClassName,
    withLogo,
  } = props

  const history = useHistory()

  const onClickToOrderPage = () => history.push(createRouterLink(EUserProfileRoute.fundingRequest, { id: String(fundingRequestId) }))

  return (
    <div className={classNames(className, 'loanProductOrderSuccess')}>
      <div className={classNames(wrapperClassName, 'loanProductOrderSuccess__wrapper')}>
        <div className="loanProductOrderSuccess__header">
          {withLogo && <GlassMorphIcon image="success" className="loanProductOrderSuccess__icon" />}

          <h3 className={classNames(titleClassName, 'loanProductOrderSuccess__title')}>{messages.title}</h3>
          <div className={classNames(subtitleClassName, 'loanProductOrderSuccess__subtitle')}>{subtitle ?? messages.subtitle}</div>
        </div>

        <div className="loanProductOrderSuccess__main">
          <NavSuccessNew classNameIcon="loanProductOrderSuccess__icon-size" navItems={navItems} itemClassName={cardClassName} />
        </div>
        <div className="loanProductOrderSuccess__footer">
          <div className="loanProductOrderSuccess__actions">
            <Button modifiers={['outline-gray', 'full-width-mobile']} onClick={resetStep}>
              {messages.actions.close}
            </Button>
            <Button modifiers={['green', 'full-width-mobile']} onClick={onClickToOrderPage}>
              {messages.actions.goToOrder}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

import Loadable from '@loadable/component'

export const AuthModal = Loadable(
  /* webpackChunkName: "AuthModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./AuthModal'),
)

export const SuccessOrderModal = Loadable(
  /* webpackChunkName: "SuccessOrderModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SuccessOrderModal/'),
)

export const FailureOrderModal = Loadable(
  /* webpackChunkName: "FailureOrderModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./FailureOrderModal/'),
)

export const InfoModal = Loadable(
  /* webpackChunkName: "InfoModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./InfoModal'),
)

export const MessageModal = Loadable(
  /* webpackChunkName: "MessageModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./MessageModal'),
)

export const CustomizedFormModal = Loadable(
  /* webpackChunkName: "CustomizedFormModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./CustomizedFormModal'),
)

export const DealAgreementModal = Loadable(
  /* webpackChunkName: "DealAgreementModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./DealAgreementModal'),
)

export const ChoosingLocationModal = Loadable(
  /* webpackChunkName: "ChoosingLocationModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./ChoosingLocationModal'),
)

export const OrganizationNoticeModal = Loadable(
  /* webpackChunkName: "OrganizationNoticeModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./OrganizationNoticeModal'),
)

export const PublicationPreviewModal = Loadable(
  /* webpackChunkName: "PublicationPreviewModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./PublicationPreviewModal'),
)

export const CartProductsWithDifferentRegionModal = Loadable(
  /* webpackChunkName: "CartProductsWithDifferentRegionModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./CartProductsWithDifferentRegionModal'),
)

export const ApplyingExpressCreditModal = Loadable(
  /* webpackChunkName: "ApplyingExpressCreditModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./ApplyingExpressCreditModal'),
)

export const CheckClientBankModal = Loadable(
  /* webpackChunkName: "CheckClientBankModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./CheckClientBankModal'),
)

export const ChangeActiveOrganisationModal = Loadable(
  /* webpackChunkName: "ChangeActiveOrganisationModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./ChangeActiveOrganisationModal'),
)

export const SuccessChangeActiveOrganisationModal = Loadable(
  /* webpackChunkName: "ChangeActiveOrganisationModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SuccessChangeActiveOrganisationModal'),
)

export const PartnerApplicationDetailsModal = Loadable(
  /* webpackChunkName: "PartnerApplicationDetailsModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./PartnerApplicationDetailsModal'),
)

export const SuccessfulRegistrationModal = Loadable(
  /* webpackChunkName: "SuccessfulRegistrationModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SuccessfulRegistrationModal'),
)

export const InnerModal = Loadable(
  /* webpackChunkName: "InnerModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./InnerModal'),
)

export const PolicyModal = Loadable(
  /* webpackChunkName: "PolicyModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./PolicyModal'),
)

export const MultipleLeasingSuccessModal = Loadable(
  /* webpackChunkName: "MultipleLeasingSuccessModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./MultipleLeasingSuccessModal'),
)

export const ConfirmModal = Loadable(
  /* webpackChunkName: "ConfirmModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./ConfirmModal'),
)
export const UploadAccreditationDocumentModal = Loadable(
  /* webpackChunkName: "UploadAccreditationDocumentModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./UploadAccreditationDocumentModal'),
)
export const SignDocumentsAccreditationWithUkepModal = Loadable(
  /* webpackChunkName: "SignDocumentsAccreditationWithUkepModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SignDocumentsAccreditationWithUkepModal'),
)

export const SuccessSendSignedDocumentOnCheckModal = Loadable(
  /* webpackChunkName: "SuccessSendSignedDocumentOnCheckModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SuccessSendSignedDocumentOnCheckModal'),
)

export const SelectSignTypeModal = Loadable(
  /* webpackChunkName: "SelectSignTypeModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SelectSignTypeModal'),
)

export const SignOfferWithUkepModal = Loadable(
  /* webpackChunkName: "SignOfferWithUkepModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SignOfferWithUkepModal'),
)

export const SignDocumentWithUkepModal = Loadable(
  /* webpackChunkName: "SignDocumentWithUkepModal
" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SignDocumentWithUkepModal/SignDocumentWithUkepModal'),
)
export const BecomeBuyerModal = Loadable(
  /* webpackChunkName: "BecomeBuyerModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./BecomeBuyerModal'),
)

export const SellerRegistrationModal = Loadable(
  /* webpackChunkName: "SellerRegistrationModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SellerRegistrationModal'),
)

export const SuccessRegistrationPartnerModal = Loadable(
  /* webpackChunkName: "SuccessRegistrationPartnerModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SuccessRegistrationPartnerModal'),
)

export const ChangeRoleModal = Loadable(
  /* webpackChunkName: "ChangeRoleModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./ChangeRoleModal'),
)

export const HowToInstallmentsModal = Loadable(
  /* webpackChunkName: "HowToInstallmentsModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./HowToInstallmentsModal'),
)

export const ConsultingFormModal = Loadable(
  /* webpackChunkName: "ConsultingFormModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./ConsultingFormModal'),
)

export const FinancingConsultingModal = Loadable(
  /* webpackChunkName: "AgentConsultingModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./FinancingConsultingModal'),
)

export const VideoModal = Loadable(
  /* webpackChunkName: "VideoModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./VideoModal'),
)

export const SellerFeedbackFormModal = Loadable(
  /* webpackChunkName: "SellerFeedbackFormModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SellerFeedbackFormModal'),
)

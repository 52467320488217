import type { FC } from 'react'
import React, { useContext, useMemo, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { Button } from '@frontend/pole-ui/lib/components/Button'
import { MultiSelect } from '@frontend/pole-ui/lib/components/MultiSelect'
import type { ViewByOptionsQueryParamsOptions } from '@/api/kubik/credit'
import { SwitchButton } from '@/components/ui'
import { Dropdown, RegionSelect, SliderValueMoney, SliderValueTerm } from '@/components/ui/ReactHookFormFields'
import { useGtm } from '@/logic/metrika/financing/gtmHelpers'
import { getUserGtmInfo } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import { pushGtmEventOnChangeFilter } from '@/pages/Financing/components/FinancingProducts/components/LoanProducts/components/LoanProductsFilter/components/LoanForm/gtm-helpers'
import { ProductsListContext } from '@/pages/Financing/constants'
import type { RootState } from '@/redux/interfaces'
import { CREDIT_OPTIONS, DEFAULT_FORM_VALUES, PURPOSE_OPTIONS, SPEED_OPTIONS, SUM_SLIDER_CONFIG, TERM_SLIDER_CONFIG } from '../../constants'
import { messages } from '../../messages'
import type { ILoanProductsFilterForm } from '../../types'
import { HiddenArea } from '../HiddenArea'
import './styles.scss'

const mapState = (state: RootState) => ({
  userGtmInfo: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

export const LoanForm: FC = () => {
  const { control, setValue, getValues, reset } = useFormContext<ILoanProductsFilterForm>()
  const { userGtmInfo, userRegion } = useSelector(mapState)
  const { isFilterChanged } = useContext(ProductsListContext)
  const { pushGtmFilter } = useGtm()
  const containerRef = useRef<HTMLDivElement>(null)

  const formValues = getValues()

  const onPurposeChange = (value: string[]) => {
    setValue('fundingPurpose', value)
    pushGtmEventOnChangeFilter(userGtmInfo, userRegion.name, messages.placeholder.purpose)
  }

  const onOptionToggle = (option: ViewByOptionsQueryParamsOptions) => {
    let result
    const alreadyHasOption = formValues.options.includes(option)

    if (alreadyHasOption) {
      result = formValues.options.filter(item => item !== option)
    } else {
      result = [...formValues.options, option]
    }

    pushGtmFilter(option, !alreadyHasOption)

    setValue('options', result)
  }

  const resetFilter = () => {
    reset(DEFAULT_FORM_VALUES)
  }

  const settingsSliderRequiredAmount = useMemo(
    () => ({ min: SUM_SLIDER_CONFIG.minValue, max: SUM_SLIDER_CONFIG.maxValue, step: SUM_SLIDER_CONFIG.step }),
    [],
  )

  const settingsSliderLoanPeriod = useMemo(
    () => ({ min: TERM_SLIDER_CONFIG.minValue, max: TERM_SLIDER_CONFIG.maxValue, step: TERM_SLIDER_CONFIG.step }),
    [],
  )

  return (
    <div className="loanForm">
      <h4 className="loanForm__title">{messages.title}</h4>

      <form className="loanForm__form">
        <RegionSelect
          isAutosuggest
          isSearchable
          control={control}
          name="regionCode"
          placeholder={messages.placeholder.region}
          modifiers={['light-grey']}
          id="filterRegionCode"
          onChange={() => pushGtmEventOnChangeFilter(userGtmInfo, userRegion.name, messages.placeholder.region)}
        />

        <div className="loanForm__selectContainer" ref={containerRef}>
          <MultiSelect
            placeholder={messages.placeholder.purpose}
            value={getValues().fundingPurpose}
            options={PURPOSE_OPTIONS}
            onChange={onPurposeChange}
            container={containerRef.current ?? undefined}
          />
        </div>

        <SliderValueMoney
          name="requiredAmount"
          settings={settingsSliderRequiredAmount}
          control={control}
          label={messages.placeholder.sum}
          className="loanForm__slider"
          pointsCount={2}
          onFocus={() => pushGtmEventOnChangeFilter(userGtmInfo, userRegion.name, messages.placeholder.sum)}
        />

        <HiddenArea>
          <SliderValueTerm
            name="loanPeriod"
            pointsType="mouth"
            settings={settingsSliderLoanPeriod}
            control={control}
            label={messages.placeholder.term}
            className="loanForm__slider"
            pointsCount={2}
            onFocus={() => pushGtmEventOnChangeFilter(userGtmInfo, userRegion.name, messages.placeholder.term)}
          />

          <Dropdown name="processingSpeed" placeholder={messages.placeholder.speed} options={SPEED_OPTIONS} control={control} />

          <div className="loanForm__switchGroup">
            {CREDIT_OPTIONS.map(option => (
              <SwitchButton
                value={formValues.options.includes(option.value)}
                name={option.value}
                label={option.label}
                tooltipText={option.tooltip}
                switcherStyle={EColor.LIGHT_GRAY}
                switcherThumbStyle={EColor.GRAY}
                key={option.value}
                labelPosition="left"
                onChange={() => {
                  onOptionToggle(option.value)
                  pushGtmEventOnChangeFilter(userGtmInfo, userRegion.name, option.label)
                }}
              />
            ))}
          </div>

          {isFilterChanged && (
            <Button onClick={resetFilter} className="loanForm__resetButton" modifiers={['outline-gray']}>
              {messages.reset}
            </Button>
          )}
        </HiddenArea>
      </form>
    </div>
  )
}

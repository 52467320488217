import type { IUser } from '@/logic/auth/interfaces'
import { getCommentFieldForConsulting } from '@/logic/financing/helpers'
import type { IApplyingFinancingOfferRequestBody } from '@/logic/financing/interfaces'
import { EFinancingProductId, EPriceUom } from '@/types'
import { normalizePhoneNumber } from '@/utils/formatUtils'
import type { IConsultingFormValues } from './types'

type TCreateConsultingRequestBodyOptions = {
  values: IConsultingFormValues
  user?: IUser
  productId?: EFinancingProductId
  noEscort?: boolean
}

type TCreateConsultingRequestBodyResult = Pick<
  IApplyingFinancingOfferRequestBody,
  'productId' | 'phone' | 'organizationName' | 'organizationInn' | 'comment' | 'agreement'
> &
  Partial<Pick<IApplyingFinancingOfferRequestBody, 'loanAmount'>>

export const createConsultingRequestBody = ({
  values,
  user,
  productId,
  noEscort,
}: TCreateConsultingRequestBodyOptions): TCreateConsultingRequestBodyResult => {
  const commonFields = {
    productId: EFinancingProductId.MARKETPLACE,
    phone: normalizePhoneNumber(values.phone),
    email: user?.email,
    agreement: true,
    comment: values.purpose || getCommentFieldForConsulting(productId, noEscort),
  }

  return {
    ...commonFields,
    organizationName: user?.organizationName,
    organizationInn: user?.organizationInn,
    loanAmount: { value: Number(values.loanAmount), uom: EPriceUom.RUB },
  }
}


export enum PurchaseTenderProcessingStatusProcessingStatus {
    "ITEMS_FILE_ERROR" = "ITEMS_FILE_ERROR",
    "ITEMS_FILE_EMPTY_ERROR" = "ITEMS_FILE_EMPTY_ERROR",
    "ITEMS_FILE_SUCCESS" = "ITEMS_FILE_SUCCESS",
    "ITEMS_FILE_IN_PROGRESS" = "ITEMS_FILE_IN_PROGRESS"
}
export type PurchaseTenderProcessingStatus = {
    /**
     * @type string
    */
    processingStatus: PurchaseTenderProcessingStatusProcessingStatus | null;
};

import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { CheckPurchaseTenderProcessingStatusQueryResponse, CheckPurchaseTenderProcessingStatusQueryParams } from "../../types/CheckPurchaseTenderProcessingStatus";

/**
     * @link /api/purchase-tenders/v1/tender/view_processing-status
     */
export async function checkPurchaseTenderProcessingStatus (params: CheckPurchaseTenderProcessingStatusQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CheckPurchaseTenderProcessingStatusQueryResponse>["data"]> {
    const { data: resData } = await client<CheckPurchaseTenderProcessingStatusQueryResponse>({
        method: "get",
        url: `/api/purchase-tenders/v1/tender/view_processing-status`,
        params,
        ...options
    });
    
    return resData;
};
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { CreateMarketingEntryMutationRequest, CreateMarketingEntryMutationResponse, CreateMarketingEntryHeaderParams } from "../../types/CreateMarketingEntry";

/**
     * @summary  Создание маркетингового касания.
     * @link /api/marketing
     */
export async function createMarketingEntry (data?: CreateMarketingEntryMutationRequest, headers?: CreateMarketingEntryHeaderParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CreateMarketingEntryMutationResponse>["data"]> {
    const { data: resData } = await client<CreateMarketingEntryMutationResponse, CreateMarketingEntryMutationRequest>({
        method: "post",
        url: `/api/marketing`,
        data,
        headers: { ...headers, ...options.headers },
        ...options
    });
    
    return resData;
};
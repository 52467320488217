import type { IBannerStore } from '@/components/composed/TelegramSubscribeOverlayBanner/types'

let bannerState: IBannerStore = {
  isShown: false,
  isClosed: false,
}

let listeners: (() => void)[] = []

export const TelegramBanner = {
  setShown(shown: IBannerStore['isShown']) {
    bannerState = {
      ...bannerState,
      isShown: shown,
    }

    emitChange()
  },
  setClosed() {
    bannerState = { ...bannerState, isClosed: true }
    emitChange()
  },

  subscribe(listener: () => void) {
    listeners = [...listeners, listener]

    return () => {
      listeners = listeners.filter(l => l !== listener)
    }
  },

  getSnapshot() {
    return bannerState
  },
}

function emitChange() {
  for (const listener of listeners) {
    listener()
  }
}

import type { FC } from 'react'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { checkUserExistByPhone } from '@/api/kubik/monolith'
import { RegistrationForm } from '@/components/composed/RegistrationForm'
import { Button } from '@/components/ui'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import { Dropdown, TextInput } from '@/components/ui/ReactHookFormFields'
import { requestStatusName } from '@/constants/financing'
import { getUser, isUserAgrarian } from '@/logic/auth/reducer'
import { useData } from '@/logic/data'
import { applyFinancingOffer } from '@/logic/financing/actions'
import type { IApplyingFinancingOfferRequestBody } from '@/logic/financing/interfaces'
import { getLegalDocumentUrl } from '@/logic/legalDocuments/helpers'
import { useGtm } from '@/logic/metrika/financing/gtmHelpers'
import { EGtmLabel } from '@/logic/metrika/financing/types'
import { getRequestState } from '@/logic/requestStatus/reducer'
import type { RootState } from '@/redux/interfaces'
import { EFinancingOfferType, EFinancingProductId, EPriceCurrency } from '@/types'
import type { TLegalDocuments } from '@/types/legalDocuments'
import { ELegalDocumentType } from '@/types/legalDocuments'
import { formatLocalizedString, normalizePhoneNumber } from '@/utils/formatUtils'
import { useApplyingFinancingOfferForm } from '@/utils/hooks/applyingFinancingOffer/useApplyingFinancingOfferForm'
import { messages } from './messages'
import type { IConsultingFormProps, IConsultingFormValues } from './types'
import { createConsultingRequestBody } from './utils'
import './styles.scss'

const mapState = (state: RootState) => ({
  requestState: getRequestState(requestStatusName)(state),
  isAgrarian: isUserAgrarian()(state),
  user: getUser()(state),
})

const ConsultingForm: FC<IConsultingFormProps> = props => {
  const {
    className,
    purpose,
    purposeOptions,
    gtmProductName,
    firstInputRef,
    inputModifiers,
    headingVariant,
    footerClassName,
    registrationFormClassName,
    shouldSkipSuccessModal = false,
    footerLinkClassName = 'underline underline_green color_green',
    onOpenRegistrationForm,
    onChangeLoginDialogOpen,
    onSuccessFormSubmit,
  } = props
  const { requestState, isAgrarian, user } = useSelector(mapState)
  const { isLoading } = requestState
  const { isAuthenticated, openAuthModal } = useAuthenticateUser()
  const { pushGtmLoanProductModalAuth, pushGtmProduct, pushGtmFormInteraction } = useGtm()
  const { data: legalDocuments = [] } = useData<TLegalDocuments>('legalDocuments')
  const linkUrl = getLegalDocumentUrl(legalDocuments, ELegalDocumentType.registrationPolicy)

  const [showRegistration, setShowRegistration] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isPhoneCheckLoading, setIsPhoneCheckLoading] = useState(false)

  const { dispatch, setOptions, profile, formValues } = useApplyingFinancingOfferForm({
    shouldSkipSuccessModal,
    productId: EFinancingProductId.MARKETPLACE,
    offerType: EFinancingOfferType.MARKETPLACE,
  })

  const initialValues = useMemo<IConsultingFormValues>(
    () => ({
      loanAmount: formValues.loanAmount?.value.toString() || '',
      purpose: purpose || formValues.comment || '',
      phone: profile.phone,
    }),
    [formValues, profile.phone, purpose],
  )

  const sendLostData = (data: Partial<IApplyingFinancingOfferRequestBody>) => {
    const productId = data.productId ?? EFinancingProductId.MARKETPLACE

    const defaultLoanAmount = {
      value: 0,
      uom: 'RUB',
    }

    const body = {
      agreement: true,
      email: data.email ?? '',
      phone: data.phone ?? '',
      comment: data.comment ?? '',
      loanAmount: data.loanAmount ?? defaultLoanAmount,
      productId,
    }

    dispatch(applyFinancingOffer({ body, isLost: true }))
  }

  const { control, handleSubmit, setValue } = useForm<IConsultingFormValues>({ defaultValues: initialValues, mode: 'onChange' })

  const heading = headingVariant !== undefined ? messages.headings[headingVariant] : undefined

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setValue('phone', normalizePhoneNumber(profile.phone)), [profile.phone])

  const onClickSubmit = () => {
    if (gtmProductName) pushGtmProduct(gtmProductName, EGtmLabel.ACTION)
  }

  const onFormInteraction = (eventLabel: string) => () => {
    if (gtmProductName) pushGtmFormInteraction(gtmProductName, eventLabel)
  }

  const onSubmit = async (values: IConsultingFormValues) => {
    const body = createConsultingRequestBody({ values, user, productId: EFinancingProductId.MARKETPLACE, noEscort: true })

    const sendForm = () => {
      dispatch(applyFinancingOffer({ body, skipSuccessModal: shouldSkipSuccessModal, onSuccess: onSuccessFormSubmit }))
    }

    if (isAuthenticated) {
      sendForm()
    } else {
      if (gtmProductName) pushGtmLoanProductModalAuth(gtmProductName)

      setIsPhoneCheckLoading(true)
      const checkUserResult = await checkUserExistByPhone({ phone: normalizePhoneNumber(values.phone) })
      setIsPhoneCheckLoading(false)

      if (checkUserResult.data?.exist) {
        onChangeLoginDialogOpen?.(true, values.phone)

        openAuthModal({
          shouldSendCodeImmediately: true,
          shouldSkipSelectRoleStep: true,
          initialValues: { phone: normalizePhoneNumber(values.phone) },
          onCloseDialog: () => {
            setOptions({
              formValues: createConsultingRequestBody({ values, user, productId: EFinancingProductId.MARKETPLACE, noEscort: true }),
            })

            onChangeLoginDialogOpen?.(false, values.phone)
          },
          onCloseModal: () => sendLostData(body),
        })
      } else {
        setPhoneNumber(values.phone)
        setShowRegistration(true)
        onOpenRegistrationForm?.(values.phone)
      }
    }
  }

  const afterRegistrationComplete = () => {
    handleSubmit(onSubmit)()
    setShowRegistration(false)
  }

  if (showRegistration) {
    return (
      <RegistrationForm
        className={registrationFormClassName}
        phoneNumber={phoneNumber}
        goBack={() => {
          setShowRegistration(false)
        }}
        afterRegistrationComplete={afterRegistrationComplete}
        shouldSkipSelectRoleStep
      />
    )
  }

  return (
    <div className={classNames('consultingForm', className)}>
      {heading && (
        <div className="consultingForm__heading">
          <h3 className="consultingForm__headingTitle">{heading.title}</h3>
          <p>{heading.description}</p>
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="consultingForm__inputs">
          <TextInput
            name="loanAmount"
            type="floatPrice"
            placeholder={messages.loanAmount}
            rules={{
              required: messages.error.required,
              min: { value: 1, message: messages.error.incorrectPrice },
            }}
            onFocus={onFormInteraction('loanAmount')}
            control={control}
            shouldBeTouchedBeforeError={false}
            isRequired
            inputRef={firstInputRef}
            inputMode="numeric"
            modifiers={inputModifiers}
          >
            <span className="uom">{EPriceCurrency.RUB}</span>
          </TextInput>

          {purposeOptions && (
            <Dropdown
              name="purpose"
              placeholder={messages.purpose}
              options={purposeOptions}
              rules={{ required: messages.error.required }}
              onFocus={onFormInteraction('loanAmount')}
              control={control}
              modifiers={inputModifiers}
              shouldBeTouchedBeforeError={false}
              isRequired
            />
          )}

          <TextInput
            id="consultingForm.phone"
            name="phone"
            type="tel"
            inputMode="numeric"
            isReadonly={isAuthenticated}
            placeholder={messages.phone}
            rules={{ required: messages.error.required }}
            onFocus={onFormInteraction('loanAmount')}
            modifiers={inputModifiers}
            control={control}
            shouldBeTouchedBeforeError={false}
            isRequired
          />
        </div>

        <Button
          type="submit"
          className="consultingForm__submit"
          modifiers={['green', 'full-width-always']}
          onClick={onClickSubmit}
          loaderProps={{ isVisible: isLoading || isPhoneCheckLoading }}
          isDisabled={!isAgrarian && isAuthenticated}
        >
          {messages.submit}
        </Button>

        <div className={classNames('text_small text_super-small-md consultingForm__footer', footerClassName)}>
          {formatLocalizedString(messages.footer.text, {
            link: (
              <CommonLink className={footerLinkClassName} url={linkUrl} shouldOpenInNewWindow>
                {messages.footer.link}
              </CommonLink>
            ),
          })}
        </div>
      </form>
    </div>
  )
}

export default memo(ConsultingForm)

import React, { forwardRef, memo, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import debounce from 'lodash/debounce'
import type {
  CalculatePaymentAmountData,
  CalculatePaymentAmountRequest,
  CalculatePaymentAmountSberDataResponse,
  CalculatePaymentAmountSberRequest,
} from '@/api/kubik/credit'
import { useCalculateRate, useCalculateRateSber } from '@/api/kubik/credit'
import { Button, Image } from '@/components/ui'
import { SliderValueMoney, SliderValueTerm } from '@/components/ui/ReactHookFormFields'
import type { IInstallmentCalculatorForm } from '@/logic/financing/interfaces'
import { ECalculatorIndex } from '@/logic/financing/interfaces'
import { getUserRegion } from '@/logic/userRegion'
import type { RootState } from '@/redux/interfaces'
import { formatLocalizedString } from '@/utils/formatUtils'
import { asyncScrollIntoView } from '@/utils/helpers'
import { useResponsiveValue } from '@/utils/hooks/responsive'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import {
  DEBOUNCE_DELAY,
  DEFAULT_VALUES,
  MILLION_RUBLES,
  PRICE_REQUEST_BUTTON_SCROLL_TO_OFFSET,
  PRODUCTS,
  RANGES,
  RANGES_FOR_SBER,
} from './constants'
import { useInstallmentCalculator, useSendGtmEventsOnFormChange } from './hooks'
import { messages } from './messages'
import type { IInstallmentCalculatorProps } from './types'
import './styles.scss'

const mapState = (state: RootState) => ({
  userRegion: getUserRegion()(state),
})

const InstallmentCalculatorComponent = forwardRef<HTMLDivElement, IInstallmentCalculatorProps>((props, ref) => {
  const {
    id = ECalculatorIndex.DEFAULT,
    className,
    fixedPrice,
    withoutTitle,
    isActive = true,
    isForLanding,
    priceRequestButton = {
      isShown: false,
    },
    createPaymentUrl,
  } = props

  const [calculateRateResult, setCalculateRateResult] = useState<CalculatePaymentAmountData[]>([])
  const [calculateRateSberResult, setCalculateRateSberResult] = useState<Maybe<CalculatePaymentAmountSberDataResponse>>(null)

  const { isDesktop } = useDeviceType()
  const { userRegion } = useSelector(mapState)
  const priceRequestButtonScrollToOffset = useResponsiveValue(PRICE_REQUEST_BUTTON_SCROLL_TO_OFFSET)

  const pointsCount = props.pointsCount ?? (isDesktop ? 3 : 2)

  const isFixedPrice = fixedPrice !== undefined

  const { mutateAsync: calculateRate, isLoading } = useCalculateRate({
    mutation: { onSuccess: data => setCalculateRateResult(data.data.data?.calculatePaymentAmountList || []) },
  })

  const { mutateAsync: calculateRateSber } = useCalculateRateSber({
    mutation: { onSuccess: data => setCalculateRateSberResult(data.data.data || null) },
  })

  const INITIAL_FORM_VALUES: IInstallmentCalculatorForm = useMemo(
    () => ({
      sum: fixedPrice ?? DEFAULT_VALUES.sum,
      days: DEFAULT_VALUES.days,
    }),
    [fixedPrice],
  )

  const { control, getValues, setValue, watch } = useForm<IInstallmentCalculatorForm>({
    defaultValues: INITIAL_FORM_VALUES,
  })
  const [sumValue, daysValue] = watch(['sum', 'days'])
  const { checkIsInactive, inactiveClassName, formatSum } = useInstallmentCalculator(sumValue, daysValue)

  const debouncedRequestRate = useMemo(
    () =>
      debounce((payload: CalculatePaymentAmountRequest) => {
        calculateRate(payload)
      }, DEBOUNCE_DELAY),
    [calculateRate],
  )

  const debouncedRequestRateSber = useMemo(
    () =>
      debounce((payload: CalculatePaymentAmountSberRequest) => {
        calculateRateSber(payload)
      }, DEBOUNCE_DELAY),
    [calculateRateSber],
  )

  useSendGtmEventsOnFormChange(control)

  useEffect(() => {
    const { sum } = getValues()

    if (sum !== INITIAL_FORM_VALUES.sum) {
      setValue('sum', INITIAL_FORM_VALUES.sum)
    }
  }, [INITIAL_FORM_VALUES.sum, getValues, setValue])

  useEffect(() => {
    if (isActive) {
      if (isForLanding) {
        debouncedRequestRateSber({ days: daysValue, sum: sumValue })
      } else debouncedRequestRate({ regionId: userRegion.code, days: daysValue, sum: sumValue })
    }
  }, [isActive, id, sumValue, daysValue, userRegion.code, isForLanding, debouncedRequestRate, debouncedRequestRateSber])

  const ranges = isForLanding ? RANGES_FOR_SBER : RANGES

  const settingsSliderSum = useMemo(
    () => ({ min: ranges.sum.minValue, max: ranges.sum.maxValue, step: ranges.sum.step, defaultValue: DEFAULT_VALUES.sum }),
    [ranges.sum.maxValue, ranges.sum.minValue, ranges.sum.step],
  )

  const settingsSliderDays = useMemo(
    () => ({ min: ranges.days.minValue, max: ranges.days.maxValue, step: ranges.days.step, defaultValue: DEFAULT_VALUES.days }),
    [ranges.days.maxValue, ranges.days.minValue, ranges.days.step],
  )

  if (!isActive) return null

  const renderButton = (isDisabled?: boolean) => (
    <Button className="installmentCalculator__button" isDisabled={isDisabled} modifiers={['green', 'small']}>
      {messages.common.select}
    </Button>
  )

  const wrapperClasses = classNames('installmentCalculator', className, {
    'installmentCalculator--landing': isForLanding,
  })

  const footNoteClasses = classNames('text_super-small', 'installmentCalculator__footnote', {
    'installmentCalculator__footnote--landing': isForLanding,
  })

  const sliderClasses = classNames('installmentCalculator__sliders', {
    'installmentCalculator__sliders--landing': isForLanding,
  })

  const onClickPriceRequestButton = () => {
    if (priceRequestButton.isShown) {
      asyncScrollIntoView(priceRequestButton.blockSelectorToScrollTo, priceRequestButtonScrollToOffset)
    }
  }

  return (
    <div ref={ref} className={wrapperClasses}>
      {!withoutTitle && <h3 className="text_body-sm installmentCalculator__title">{messages.title}</h3>}
      <div className={sliderClasses}>
        {!isFixedPrice && (
          <SliderValueMoney
            name="sum"
            label={messages.labels.sum}
            settings={settingsSliderSum}
            pointsCount={pointsCount}
            control={control}
          />
        )}
        <SliderValueTerm
          name="days"
          label={messages.labels.days}
          pointsType="days"
          settings={settingsSliderDays}
          pointsCount={pointsCount}
          control={control}
        />
      </div>
      {isForLanding ? (
        <div className="installmentCalculator__landingResults">
          <div className="installmentCalculator__landingResultsBanner">
            <div className="installmentCalculator__landingResultsBannerInfo">
              <div className="installmentCalculator__landingResultsBannerTitle">{messages.banner.title}</div>
              {calculateRateSberResult?.calculatePaymentAmount && (
                <h3 className="installmentCalculator__landingResultsSumm">{formatSum(calculateRateSberResult.calculatePaymentAmount)}</h3>
              )}
            </div>
            <CommonLink url="/agricultural-products">
              <Button modifiers={['green', 'small', 'full-width-mobile']} className="installmentCalculator__landingResultsButton">
                {messages.banner.button}
              </Button>
            </CommonLink>
          </div>
          <div className={footNoteClasses}>{messages.footnote}</div>
        </div>
      ) : (
        <>
          <div className="installmentCalculator__results">
            <div className="installmentCalculator__details">
              {calculateRateResult?.map(({ productId }) => {
                const { link, maxSum, textDescription, logo } = PRODUCTS[productId]

                const limitDescription = formatLocalizedString(messages.product.limit.maxSum, {
                  sumInMillions: maxSum / MILLION_RUBLES,
                })

                return (
                  <div
                    className={classNames('text_super-small-sm installmentCalculator__detail', inactiveClassName(productId))}
                    key={productId}
                  >
                    <div className="installmentCalculator__logoContainer">
                      <Image className="installmentCalculator__logo" src={logo} isInStatic />
                    </div>
                    <p>{textDescription.firstLine}</p>
                    {!isFixedPrice && <p>{limitDescription}</p>}
                    <p>{textDescription.lastLine}</p>
                    {link && (
                      <CommonLink className="active" url={link}>
                        {messages.more}
                      </CommonLink>
                    )}
                  </div>
                )
              })}
            </div>
            <div className="installmentCalculator__summaries">
              {calculateRateResult?.map(({ productId, paymentId, calculatePaymentAmount }) => (
                <div className="installmentCalculator__summary" key={productId}>
                  <div className="installmentCalculator__calculation">
                    <div className={classNames(inactiveClassName(productId, isLoading))}>
                      {checkIsInactive(productId) ? (
                        <span className="installmentCalculator__mdash">&mdash;</span>
                      ) : (
                        <>
                          <h6 className="text_base">{formatSum(calculatePaymentAmount)}</h6>
                          {messages.refundableAmount}
                        </>
                      )}
                    </div>
                  </div>
                  {createPaymentUrl !== undefined &&
                    (isLoading || checkIsInactive(productId) ? (
                      renderButton(true)
                    ) : (
                      <CommonLink url={createPaymentUrl(paymentId)}>{renderButton()}</CommonLink>
                    ))}
                </div>
              ))}
            </div>
          </div>
          {priceRequestButton.isShown && (
            <Button
              className="installmentCalculator__priceRequestButton"
              onClick={onClickPriceRequestButton}
              modifiers={['green', 'full-width-mobile']}
            >
              {messages.button.priceRequest}
            </Button>
          )}
          <div className={footNoteClasses}>{messages.footnote}</div>
        </>
      )}
    </div>
  )
})

export const InstallmentCalculator = memo(InstallmentCalculatorComponent)
